import { Document } from '@contentful/rich-text-types';
import { Asset, Entry } from 'contentful';

import {
  TitleWithTextEntryCmsPosition,
  TitleWithTextEntryCmsTitleTheme,
} from './views/sections';

export type SectionEntryCms = Entry<{
  slug?: string;
}>;

export enum PageThemeCms {
  EAIR = 'Air',
  ESN = 'Star Night',
  EDS = 'Die Sprechstunde',
  ELS = 'Live Session',
  GAME = 'Game',
  LEGAL = 'Legal',
  MUSIC_AWARD = 'Music-Award',
}

export interface LinkTargetCms {
  slug: string;
  theme?: PageThemeCms;
  parent?: Entry<PageCms>;
}

export interface PageCms extends LinkTargetCms {
  title: string;
  body: SectionEntryCms[];
  seo?: Entry<SeoEntryCms>;
}

export interface PageEntryCms extends PageCms {
  background?: Asset;
  backgroundMobile?: Asset;
  deactivateAdSlots?: boolean;
  isIndexed?: boolean;
  hasInvertedThemeColor?: boolean;
}

export interface CategoryEntryCms extends PageCms {
  image: Asset;
  smallImage: Asset;
  background?: Asset;
  backgroundMobile?: Asset;
  description?: Document;
  teaser: Entry<TeaserEntryCms>;
}

export interface ArticleEntryCms extends Omit<PageCms, 'body'> {
  publishDate: string;
  profiles?: Entry<ProfileEntryCms>[];
  contentCategoryList?: string[];
  headerImage: Asset;
  headerVideo?: string;
  teaserImage: Asset;
  lead?: Document;
  body: Document;
  deactivateAdSlots?: boolean;
  isIndexed?: boolean;
  showInRelatedArticles?: boolean;
}

export interface InfoPageEntryCms extends PageCms {
  content: Document;
  mediaDownloadText?: string;
  mediaFiles?: Asset[];
  parentPage: Entry<PageCms>;
  deactivateAdSlots?: boolean;
}

export interface AccountEntryCms {
  title: string;
  slug: string;
  accountNavigation: Entry<NavItemEntryCms>;
}

export interface ContentCreatorsEntryCms {
  title: string;
  slug: string;
}

export interface SeoEntryCms {
  title: string;
  description: string;
  image?: Asset;
}

export interface FileEntryCms {
  url: string;
  fileName: string;
  contentType: string;
}

export interface LinkEntryCms {
  title: string;
  displayTitle?: string;
  targetUrl?: string;
  targetPage?: Entry<PageCms>;
  file?: Asset;
  newTab?: boolean;
  action?: LinkActionTypeCms;
}

export interface NavItemEntryCms extends LinkEntryCms {
  title: string;
  navIcon?: Asset;
  cardImage?: Asset;
}

export interface NavigationEntryCms {
  title: string;
  slug: string;
  displayTitle?: string;
  socialNavigation?: boolean;
  navItems: Entry<NavItemEntryCms>[];
}

export interface VariableEntryCms {
  title: string;
  slug: string;
  text: Document;
}

export interface SlideEntryCms {
  title: string;
  description?: Document;
  background: Asset;
  backgroundMobile?: Asset;
  link?: Entry<LinkEntryCms>;
}

export interface TitleWithTextEntryCms {
  title: string;
  text?: Document;
  theme: TitleWithTextEntryCmsTitleTheme;
  position?: TitleWithTextEntryCmsPosition;
  showTitle?: boolean;
}

export interface SliderEntryCms {
  title: string;
  sliderItems: Entry<SlideEntryCms>[];
}

export interface TeaserEntryCms {
  title: string;
  overlineText: string;
  image: Asset;
  imageMobile?: Asset;
  description: Document;
  link?: Entry<LinkEntryCms>;
}

export interface ImageEntryCms extends LinkEntryCms {
  title: string;
  image: Asset;
  imageMobile?: Asset;
  link?: Entry<LinkEntryCms>;
}

export enum BoxType {
  LargeImgRight = 'Large (Bild rechts)',
  LargeImgLeft = 'Large (Bild links)',
  Medium = 'Medium',
  Small = 'Small',
  Contact = 'Contact',
}

export enum BoxTheme {
  Default = 'Default',
  Secondary = 'Secondary',
}

export interface BoxEntryCms {
  boxTitle?: string;
  title: string;
  image: Asset;
  description?: Document;
  overlineText?: string;
  links?: Entry<LinkEntryCms>[];
  type?: BoxType;
  theme?: BoxTheme;
}

export interface EmbedEntryCms {
  title: string;
  embedUrl: string;
}

export interface SectionSectionEntryCms {
  title?: string;
  showTitle?: boolean;
  link?: Entry<LinkEntryCms>;
  elements: SectionEntryCms[];
}

export interface StationEntryCms {
  title: string;
  slug: string;
  description?: Document;
  bmId: string;
  siblings: Entry<StationEntryCms>[];
  siblingShortName?: string;
  frequency?: string;
  whatsappNumber: string;
  studioNumber?: string;
  background?: Asset;
  backgroundMobile?: Asset;
  backgroundPlayouts?: Asset;
  backgroundPlayoutsMobile?: Asset;
  body: SectionEntryCms[];
  seo?: Entry<SeoEntryCms>;
  fallbackStreamUrl: string;
}

export type ContentFeedEntryCmsEntryType =
  | 'video'
  | 'soundpiece'
  | 'meme'
  | 'category'
  | 'station'
  | 'channel'
  | 'podcast'
  | 'show'
  | 'format'
  | 'profile'
  | 'cardTeaser'
  | 'contentCategory';

export interface ContentFeedEntryCmsEntry {
  type: ContentFeedEntryCmsEntryType;
  // contentful ids are strings, tagging tool ids are numbers
  entryId: string | number;
  position?: string; // TODO: should be changed to Number in Content Feed Editor
}

export enum ContentFeedEntryCmsEntryTypeGroup {
  Videos = 'Videos',
  Memes = 'Memes',
  Categories = 'Kategorien',
  Stations = 'Stations',
  Creators = 'Creators',
  Channels = 'Channels',
  Podcasts = 'Podcasts',
  Soundpieces = 'Soundpieces',
  ShowsFormatsProfiles = 'Shows, Formate & Profile',
  ChannelsPodcasts = 'Channels & Podcasts',
}

export enum ContentFeedEntryCmsDisplayStyle {
  Grid = 'Grid',
  Carousel = 'Carousel',
}

export enum ContentCategoryType {
  Sponsored = 'Branded Content',
}

export interface ContentFeedTagCms {
  taggingToolId: number;
  title: string;
  contentfulEntityId: string;
  contentfulTypeId: string;
}

export interface ContentFeedEntryCms {
  title: string;
  // displayTitle: string;
  hideTitle: boolean;
  linkText?: string;
  targetPage?: Entry<PageEntryCms>;
  targetURL?: string;
  displayStyleMobile?: ContentFeedEntryCmsDisplayStyle;
  displayStyleDesktop?: ContentFeedEntryCmsDisplayStyle;
  numberOfRows?: number;
  entryTypeGroup: ContentFeedEntryCmsEntryTypeGroup;
  manualEntries?: ContentFeedEntryCmsEntry[];
  automaticEntries?: boolean;
  tagsToInclude?: ContentFeedTagCms[];
  tagsToExclude?: ContentFeedTagCms[];
  entryTypesToInclude?: ContentFeedEntryCmsEntryType[];
}

export interface TagCms extends LinkTargetCms {
  title: string;
  bmId: string;
  image: Asset;
}

export interface VideoEntryCms {
  title: string;
  taggingToolVideoId?: string;
  videoUrl?: string;
}

export type BodyEntryCms = Entry<unknown>;

export interface ShowEntryCms extends PageCms {
  bmId?: string;
  description?: string;
  station?: Entry<unknown>;
  hosts?: Entry<ProfileEntryCms>[];
  siblings?: Entry<ShowEntryCms>[];
  siblingShortName?: string;
  cover: Asset;
  backgroundImage: Asset;
  backgroundImageMobile?: Asset;
  isIndexed?: boolean;
}

export interface ProfileEntryCms extends PageCms, TagCms {
  background?: Asset;
  backgroundMobile?: Asset;
  description?: Document | string;
  isCreator?: boolean;
  toolsAbbreviation?: string;
}

export interface FormatEntryCms extends PageCms {
  bmId: string;
  siblings?: Entry<FormatEntryCms>[];
  siblingShortName?: string;
  description?: Document;
  creators?: Entry<ProfileEntryCms>[];
  shows?: Entry<ShowEntryCms>;
  image?: Asset;
  background: Asset;
  backgroundMobile?: Asset;
  isIndexed?: boolean;
}

export interface PodcastEntryCms extends PageCms {
  rssFeedUrl: string;
  description?: string;
  imageUrl: string;
  creators?: Entry<ProfileEntryCms>[];
  background?: Asset;
  backgroundImage?: Asset;
  backgroundImageMobile?: Asset;
  image?: Asset;
}

export interface RichTextEntryCms {
  title: string;
  body: Document;
}

export enum StaticComponentTypeCms {
  JobsGrid = 'Jobs Grid',
  WeitereChannels = 'Weitere Radios',
  Newsletter = 'Newsletter',
  TeamGrid = 'Team Grid',
  KategorienAZ = 'Kategorien A-Z',
  ContactForm = 'Kontakt Formular',
}

export interface StaticComponentEntryCms {
  title: string;
  showTitle: boolean;
  newsletterDesktopImage?: Asset;
  newsletterMobileImage?: Asset;
  newsletterSuccessImage?: Asset;
  newsletterErrorImage?: Asset;
  component: StaticComponentTypeCms;
  jobType?: string;
}

export interface JobEntryCms {
  title: string;
  slug: string;
  pensum: string;
  jobType: string;
  department: string;
  location: string;
  url: string;
}

export interface ImageWithTextEntryCms {
  title: string;
  showTitle: boolean;
  link?: Entry<LinkEntryCms>;
  image: Asset;
  text?: Document;
}

export enum GridEntryTypeCms {
  Info = 'Info',
  Partner = 'Partner',
}

export interface GridEntryCms {
  title: string;
  showTitle: boolean;
  type: GridEntryTypeCms;
  items: Entry<ImageWithTextEntryCms>[];
  numberOfColumns: 1 | 2 | 3 | 4 | 5 | 6;
}

export interface SettingsEntryCms {
  watchOverviewFilters: Entry<TagCms>[]; // Could be more specific
  contactPageRereference: Entry<LinkEntryCms>;
  audioSnippetsFilters: Entry<TagCms>[]; // Could be more specific
  adRefreshDelay: number;
  preRollRateWeb: number;
  // There are more props in CMS
}

export interface ArticleTeaserContainerEntryCms {
  title?: string;
  showTitle: boolean;
  link?: Entry<LinkEntryCms>;
  articles: Entry<ArticleEntryCms>[];
}

export interface CardTeaserEntryCms {
  title: string;
  overlineText: string;
  image: Asset;
  description?: Document;
  link?: Entry<LinkEntryCms>;
}

// PodigeePodcastId will be required for api requests
export interface PodcastEmbedEntryCms {
  playlistEmbedTitle: string;
  podigeePodcastId: string;
  podcastSubdomain: string;
}

export interface GalleryEntryCms {
  title: string;
  slug: string;
  theme: PageThemeCms;
  description?: Document;
  teaserImage: Asset;
  media: Asset[];
  seo?: Entry<SeoEntryCms>;
}

export enum GalleryBoxTheme {
  Rows = 'Rows',
  Columns = 'Columns',
}

export interface GalleriesContainerEntryCms {
  title: string;
  boxTheme?: GalleryBoxTheme;
  hideTitle?: boolean;
  link?: Entry<LinkEntryCms>;
  galleries: Entry<GalleryEntryCms>[];
}

export interface EventHeroEntryCms {
  title: string;
  heroType: string;
  link?: Entry<LinkEntryCms>;
  showButton: boolean;
  primaryBackgroundDesktop: Asset;
  primaryBackgroundMobile?: Asset;
  secondaryBackgroundDesktop?: Asset;
  secondaryBackgroundMobile?: Asset;
  backgroundColor: string;
  textColor: string;
  text?: string;
  foreground?: Asset;
  foregroundMobile?: Asset;
  isMaxWidth?: boolean;
  videoPlaceholder: Asset;
  streamUrl: string;
}

export interface FaqEntryCms {
  title: string;
  titleTagComponent: string;
  faqItems: Entry<FaqItemEntryCms>[];
  link?: Entry<LinkEntryCms>;
}

export interface FaqItemEntryCms {
  title: string;
  body: Document;
}

export interface LotteryHeaderEntryCms {
  lotteryId: number;
  poolId: number;
  background: Asset;
  backgroundMobile?: Asset;
  title?: string;
  smallHeadline?: string;
  description?: string;
  titleAlreadyParticipated: string;
  descriptionAlreadyParticipated: string;
  titleLogin: string;
  descriptionLogin?: string;
  titleIncompleteProfile?: string;
  descriptionIncompleteProfile: string;
  titleAlreadyWon: string;
  descriptionAlreadyWon?: string;
  titleInactive: string;
  descriptionInactive?: string;
  titleError?: string;
  descriptionError: string;
  ctaButtonText: string;
  ctaShareText: string;
  ctaLoginText: string;
  ctaInactiveText?: string;
  ctaIncompleteProfileText: string;
  urlRaffleEnd?: string;
  linkRaffleEnd?: Entry<LinkEntryCms>;
  webRaffleStart?: string;
  webRaffleEnd?: string;
  titleParticipationSuccess: string;
  descriptionParticipationSuccess: string;
}

export interface ActEntryCms extends PageEntryCms {
  headerImage?: Asset;
  description: Document;
  ctaText: string;
  teaserImage: Asset;
  teaserImageMobile?: Asset;
  theme: PageThemeCms;
  artistName?: string;
  sessionDate?: string;
  sessionLocation?: string;
  sessionNumber?: string;
}

export interface ThemeSettingsEntryCms {
  title: string;
  slug: string;
  eventUrlSlug: string;
  eventNavigation?: Entry<NavigationEntryCms>;
  footerNavigations: Entry<NavigationEntryCms>[];
  logo: Asset;
  logoMobile?: Asset;
  locationTimeImage: Asset;
  energyLogo?: Asset;
  notFoundPageBg?: Asset;
  errorPageBackground?: Asset;
}

// PodigeePodcastId will be required for api requests
export interface SoundpiecePlaylistEntryCms {
  title: string;
  description: string;
}

export interface LotteryFormEntryCms {
  title: string;
  lotteryId: string;
  teaserTitle: string;
  teaserText?: Document;
  formTag?: string;
  ctaText?: string;
  deadline?: Date;
}

export interface IconLinkEntryCms {
  title: string;
  icon: Asset;
  link: Entry<LinkEntryCms>;
}

export interface ContentfulError {
  sys?: {
    id: string;
  };
}

export enum LinkActionTypeCms {
  OpenNewsletter = 'Newsletter öffnen',
  EditProfile = 'Profil bearbeiten',
}

export interface QuizEntryCms {
  title: string;
  slug: string;
  deactivateAdSlots?: boolean;
  displayTitle?: boolean;
  correctTitle?: string;
  correctMessage?: string;
  invalidAnswerTitle?: string;
  invalidAnswerMessage?: string;
  messageAlreadyAttended?: string;
  messageWithoutQuiz?: string;
  sweepstakePrizeId?: string;
  sweepstakeName?: string;
  userNotWonTitle?: string;
  userNotWonMessage?: string;
  validProfileWonTitle?: string;
  validProfileWonMessage?: string;
  notValidProfileWonTitle?: string;
  notValidProfileWonMessage?: string;
  loginForResultsMessage?: string;
  hideAdditionalWideboardAds: boolean;
  marketingOptInText?: string;
}

export enum MusicAwardDisplayType {
  nominees = 'Die Nominierten',
  winners = 'Die Gewinner',
}

export interface MusicAwardEntryCms {
  displayTitle?: string;
  displayData: string;
  votingStart: string;
  votingEnd: string;
  year?: string;
}

export interface MusicAwardActEntryCms {
  title: string;
  image: Asset;
  lotteryId: number;
  lotteryPool: number;
  year: string;
  isWinner: boolean;
  url: string;
}

export interface PageTeaserEntryCms {
  title: string;
  description?: Document;
  links?: Entry<LinkEntryCms>[];
}
